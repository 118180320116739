<template>
     <div class="flexdc flex1" style="height:100%">
    <div class="operationControl flexdc operationControlTest" style="align-items: flex-start;">
          <div class="searchbox">
            <div>
              <div title="试卷名称" class="searchboxItem" style="display:flex;align-items: center;">
                <span
                  class="itemLabel"
                  style="min-width: 5rem; text-align: right; font-size: 14px;padding: 0 0.5rem;"
                >试卷名称:</span>
                <el-input
                  v-model="searchName"
                  type="text"
                  size="small"
                  placeholder="请输入试题名称"
                  clearable
                />
              </div>
              <div title="试卷来源" class="searchboxItem" style="display:flex;align-items: center;">
                <span
                  class="itemLabel"
                  style="min-width: 5rem; text-align: right; font-size: 14px;padding: 0 0.5rem;"
                >试卷来源:</span>
                <el-select
                  v-model="ruleForm.paperOrgin"
                  placeholder="请选择试卷来源"
                  clearable
                  size="small"
                >
                  <el-option label="上传试卷" value="10"></el-option>
                  <el-option label="平台" value="20"></el-option>
                  <el-option label="组卷" value="30"></el-option>
                </el-select>
              </div>
              <div class="df">
                <transition name="el-zoom-in-center">
                  <el-button
                    class="bgc-bv"
                    round
                    @click="getData()"
                    size="small"
                    v-show="dialogVisible == false"
                  >查询</el-button>
                </transition>
                <el-button @click="search" type="text">
                  高级搜索
                  <i :class="dialogVisible? 'el-icon-top':'el-icon-bottom'"></i>
                </el-button>
              </div>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="addTestPaper()">新增试卷</el-button>
              <el-button type="primary" class="bgc-bv" round @click="bulkImportPaper">批量导入</el-button>
              <el-button class="bgc-bv" round @click="open" size="small">自定义表头</el-button>
            </div>
          </div>

          <el-collapse-transition>
            <div class="searchbox" style="margin-top:10px" v-show="dialogVisible == true">
              <el-form label-width="80px" ref="ruleForm" :model="ruleForm">
                <el-form-item label="审核状态:" prop="auditState">
                  <el-select
                    v-model="ruleForm.auditState"
                    placeholder="请选择审核状态"
                    clearable
                    size="small"
                  >
                    <el-option label="未提审" value="10"></el-option>
                    <el-option label="待审核" value="20"></el-option>
                    <el-option label="已通过" value="30"></el-option>
                    <el-option label="已驳回" value="40"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="销售状态:" prop="salesState" style="margin-left:10px">
                  <el-select
                    v-model="ruleForm.salesState"
                    placeholder="请选择销售状态"
                    clearable
                    size="small"
                  >
                    <el-option label="未售" value="10"></el-option>
                    <el-option label="在售" value="20"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="培训类型:" prop="Trainingtype" clearable>
                  <tree
                    ref="tree"
                    @eventBtn="childBack"
                    :ruleForm="ruleForm"
                    size="small"
                    @clearParams="clearParams"
                    :modal="false"
                     typeStu
                  />
                </el-form-item>
                <el-form-item style="margin-left:10px;width:20%" class="btns">
                  <el-button class="bgc-bv" round @click="getData()" size="small">查询</el-button>
                  <el-button @click="cancleForm('ruleForm')" type="text" style="padding:0 ">清空搜索条件</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-transition>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
              v-if="showtable"
            >
              <el-table-column fixed label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column
                label="试卷名称"
                align="left"
                show-overflow-tooltip
                prop="paperName"
                min-width="200"
                fixed
              />
              <!-- <template v-if="!tableHaed.length">
                <el-table-column
                  label="培训类型"
                  align="center"
                  show-overflow-tooltip
                  prop="trainTypeNamePath"
                >
                  <template slot-scope="scope">{{scope.row.trainTypeNamePath || '--'}}</template>
                </el-table-column>
                <el-table-column label="行业类型" align="center" show-overflow-tooltip width="160">
                  <template slot-scope="scope">{{scope.row.industryNamePath || '--'}}</template>
                </el-table-column>
                <el-table-column label="岗位类型" align="center" show-overflow-tooltip width="160">
                  <template slot-scope="scope">{{scope.row.postName || '--'}}</template>
                </el-table-column>
                <el-table-column label="职业/工种" align="center" show-overflow-tooltip width="200">
                  <template slot-scope="scope">{{scope.row.occupationNamePath || '--'}}</template>
                </el-table-column>
                <el-table-column label="培训等级" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">{{scope.row.trainLevelName || '--'}}</template>
                </el-table-column>
                <el-table-column label="审核状态" align="center">
                  <template slot-scope="scope">
                    <span>
                      {{
                      $setDictionary("AUDITSTATE", scope.row.auditState)
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="试卷来源" align="center">
                  <template slot-scope="scope">
                    <span>
                      {{
                      $setDictionary("PAPERORGIN", scope.row.paperOrgin)
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="销售状态" align="center">
                  <template slot-scope="scope">
                    <span>
                      {{
                      scope.row.paperOrgin == '20'? '--' : $setDictionary("SELLSTATE", scope.row.salesState)
                      }}
                    </span>
                  </template>
                </el-table-column>
              </template>-->
              <template v-if="tableHaed.length">
                <el-table-column
                  v-for="(el,index) in tableHaed"
                  :key="index"
                  :label="el.label"
                  :align="el.textAlign"
                  show-overflow-tooltip
                  :min-width="el.width"
                >
                  <!-- <template slot-scope="scope">
                    {{
                    getValue(el,scope.row)
                    }}
                  </template> -->
                    <template slot-scope="scope">
              <span :class="el.label == '销售状态' &&scope.row.salesState == '10' && scope.row.paperOrgin == '10'?'textRed':''">{{ getValue(el,scope.row)}}</span>
            </template>
                </el-table-column>
              </template>

              <el-table-column label="操作" align="center" width="220px" fixed="right">
                <div slot-scope="scope" class="df">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="seePaperEdit(scope.row.paperId,scope.row.paperOrgin)"
                  >查看</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="deletePaper(scope.row.paperId)"
                  >删除</el-button>

                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px;color:red"
                    v-if="scope.row.paperOrgin != '20'&&scope.row.salesState == 10"
                    @click="salesState(scope.row.paperId, 10)"
                  >上架</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    v-if="scope.row.paperOrgin != '20'&&scope.row.salesState == 20"
                    @click="salesState(scope.row.paperId, 20)"
                  >下架</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    v-if="scope.row.auditState == 10"
                    @click="paperAudit(scope.row.paperId, 10)"
                  >提交审核</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    v-if="scope.row.auditState == 20"
                    @click="paperAudit(scope.row.paperId, 20)"
                  >撤销审核</el-button>
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="copyPaper(scope.row.paperId)"
                  >复制</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog
      title="自定义表头"
      :visible.sync="tableHeadDialog"
      width="30%"
      class="custom"
      @close="cencal"
    >
      <p style="padding-left:20px;margin-bottom:10px;">勾选需要显示在列表上的项，可拖动调整顺序</p>
      <div>
        <el-tree
          ref="headTree"
          :data="treeDataCopy"
          default-expand-all
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
        >
          <template slot-scope="{node,data}">
            <el-checkbox
              @change="(val)=>{handleCheckChange(val,data)}"
              v-model="data.checked"
              :disabled="data.label == '课程名称'"
            >{{node.label}}</el-checkbox>
          </template>
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencal">取 消</el-button>
        <el-button class="bgc-bv" @click="resetHeadOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
import { console } from "@/utils/helper";
export default {
  name: "testPaperList",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
        activeName: "first",
      showtable: false,
      searchName: "",
      searchIdcard: "",
      searchPhone: "",
      dialogVisible: false,
      ruleForm: {
        paperOrgin: "",
        auditState: "",
        salesState: "",
        Trainingtype: ""
      },
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      datatreeList: [],
      options: [], // 高级搜索
      options1: [], // 高级搜索
      options2: [], // 高级搜索
      options3: [], // 高级搜索
      params: {},
      // 自定义表头
      tableHeadDialog: false, //
      treeData: [
        {
          label: "培训类型", //表头名称
          propName: "trainTypeNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "140", // 列的宽
          order: 1,
          id: "1",
          checked: true
        },
        {
          label: "行业类型", //表头名称
          propName: "industryNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 2,
          id: "2",
          checked: true
        },
        {
          label: "岗位类型", //表头名称
          propName: "postName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 3,
          id: "3",
          checked: true
        },
        {
          label: "职业/工种", //表头名称
          propName: "occupationNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 4,
          id: "4",
          checked: true
        },
        {
          label: "培训等级", //表头名称
          propName: "trainLevelName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 5,
          id: "5",
          checked: true
        },
        {
          label: "审核状态", //表头名称
          propName: "auditState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "AUDITSTATE", //字典对应key
          width: "120", // 列的宽
          order: 6,
          id: "6",
          checked: true
        },
        {
          label: "试卷来源", //表头名称
          propName: "paperOrgin", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "PAPERORGIN", //字典对应key
          width: "100", // 列的宽
          order: 7,
          id: "7",
          checked: true
        },
        {
          label: "销售状态", //表头名称
          propName: "salesState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "SELLSTATE", //字典对应key
          width: "120", // 列的宽
          order: 8,
          id: "8",
          checked: true
        }
      ],
      tableHaed: [],
      treeDataCopy: []
    };
  },
  created() {
    this.getTableHeight();
    const query = this.$route.query;
    if (query.active) {
      this.activeName = query.active;
    } else {
      this.activeName = "testpaper";
    }
    this.getTableHead();
  },
  computed: {
    ...mapGetters({
      getTraintype: "getTraintype",
      userJson:'getUser'
    })
  },
  methods: {
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        if (el.propName == "auditState") {
          if (row.auditState == "30") {
            return "已通过";
          } else if (row.auditState == "40") {
            return "已驳回";
          } else {
            return this.$setDictionary(el.dictionaries, row.auditState);
          }
        } else if (el.propName == "salesState") {
          if (row.paperOrgin == "20") {
            return "--";
          } else {
            return this.$setDictionary(el.dictionaries, row.salesState);
          }
        } else {
          return this.$setDictionary(el.dictionaries, row[el.propName]);
        }
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss")
            : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else {
        // 其他
        return row[el.propName] || "--";
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHaed = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "myTestpaper"
      }).then(res => {
        this.getTableHead();
      });
    },
    open() {
      this.tableHeadDialog = true;
      this.handle();
    },
    cencal() {
      this.tableHeadDialog = false;
    },
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "myTestpaper"
      })
        .then(res => {
          if (res.status == 0) {
            const data = res.data || {};
            let list = [];
            if (data.configInfo && data.configInfo != "") {
              list = JSON.parse(data.configInfo);
            }
            if (list.length) {
              list.map(el => {
                this.treeData.forEach((it, index) => {
                  if (el.id == it.id) {
                    it.checked = el.checked;
                    it.order = el.order;
                  }
                });
              });
              this.treeData.sort((a, b) => {
                return a.order - b.order;
              });
              this.handle();
            } else {
              this.handle();
            }
          } else {
            // this.tableHaed = [...this.treeData];
            this.handle();
          }
        })
        .catch(err => {
          this.tableHaed = [...this.treeData];
        });
    },
    handle() {
      this.tableHaed = [];
      this.treeDataCopy = [];
      this.treeData.forEach(el => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      this.treeDataCopy.forEach(el => {
        if (el.checked) {
          this.tableHaed.push(el);
        }
      });
      this.showtable = true;
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      if (draggingNode.label == "课程名称") {
        return false;
      }
      return true;
    },
    handleCheckChange(val, data) {
      // console.log(data);
    },
    /**
     *
     */
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    init() {
      this.getData();
      this.getList();
    },
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperName: this.searchName || ""
      };
      if (this.ruleForm.auditState) {
        params.auditState = this.ruleForm.auditState;
      }
      if (this.ruleForm.paperOrgin) {
        params.paperOrgin = this.ruleForm.paperOrgin;
      }
      if (this.ruleForm.salesState) {
        params.salesState = this.ruleForm.salesState;
      }
      // 培训类型 trainForm postForm occForm levelForm industryForm
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/paper/queryList",
        params,
        pageNum
      });
    },
    seePaperEdit(id, paperOrgin) {
      this.$router.push({
        path: "/web/testPaperEdit",
        query: {
          id,
          paperOrgin
        }
      });
    },
    getList() {
      this.dialogVisible = false;
      if (this.getTraintype.length) {
        this.datatreeList = this.getTraintype;
        return;
      }
      this.$post("/sys/category/train-type/tree").then(ret => {
        const retdata = ret.data || [];
        if (retdata.length) {
          this.datatreeList = retdata;
          this.$store.dispatch("handleTraintype", retdata);
        }
      });
    },
    paperAudit(paperId, stu) {
      if (stu === 20) {
        this.doDel({
          title: "撤销审核",
          url: "/biz/paper/modifyAuditState",
          msg: "该试卷已经提交审核，是否撤销审核？",
          ps: {
            type: "post",
            data: { resourceId: paperId, auditState: "10" }
          }
        });
      } else {
        this.doDel({
          title: "提交审核",
          url: "/biz/paper/modifyAuditState",
          msg: "试卷只有审核通过才可以使用，您是否提交试卷审核？",
          ps: {
            type: "post",
            data: { resourceId: paperId, auditState: "20" }
          }
        });
      }
    },
    salesState(paperId, stu) {
      if (stu === 20) {
        this.doDel({
          title: "下架",
          url: "/biz/paper/modifySalesState",
          msg: "你确定要将该试卷从平台市场下架吗？",
          ps: {
            type: "post",
            data: { resourceId: paperId, salesState: "10" }
          }
        });
      } else {
        this.doDel({
          title: "上架",
          url: "/biz/paper/modifySalesState",
          msg: "你确定要将该试卷发布到平台市场吗？",
          ps: {
            type: "post",
            data: { resourceId: paperId, salesState: "20" }
          }
        });
      }
    },
    copyPaper(paperId) {
      this.$post("/biz/paper/copyPaper", {paperId})
        .then(ret => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "修改成功"
            });
            this.getData(-1);
          }
        })
        .catch(err => {
          return;
        });
    },
    deletePaper(paperId) {
      this.doDel({
        title: "删除",
        url: "/biz/paper/deletePaper",
        msg:
          "你确定要删除该试卷吗?课件/课程关联了该试卷，课件/课程中的试卷也会被同时删除？",
        ps: {
          type: "post",
          data: { paperId: paperId }
        }
      });
    },
    // 新增 编辑
    addTestPaper(row) {
      if (row) {
        this.$router.push({
          path: "/web/addTestPaper",
          query: row
        });
      } else {
        this.$router.push({
          path: "/web/addTestPaper"
        });
      }
    },
    // 批量导入
    bulkImportPaper() {
      this.$router.push({
        path: "/web/bulkImportPaper"
      });
    },
    search() {
      this.dialogVisible = !this.dialogVisible;
      this.getTableHeight();
    },
    //清空
    cancleForm(formName) {
      this.params = {};
      this.ruleForm = {
        paperOrgin: "",
        auditState: "",
        salesState: "",
        Trainingtype: ""
      };
      this.searchName = "";
    },
    // 删除
    delCertificate(ind) {
      this.addlist.splice(ind, 1);
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.dialogVisible == false
            ? 90 + 0.675 * 16 + 1
            : 120 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function(route) {
      if(route.name !='testPaperList') return;
      if (route.params.refresh) {
        if (route.params.isAdd) {
          this.searchName = "";
          this.ruleForm = {
            paperOrgin: "",
            auditState: "",
            salesState: "",
            Trainingtype: ""
          };
          this.params = "";
          this.getData();
        } else {
          this.getData(-1);
        }
      }
    }
  }
};
</script>
<style lang="less">
.operationControlTest {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
.textRed {
  color:red
}
</style>
